import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import GoogleFeed from "../components/GoogleFeed";
import TheVergeFeed from "../components/TheVergeFeed";
import TechRepublicFeed from "../components/TechRepublicFeed";
import ArticleItem from "../components/ArticleItem";
import FeaturedArticleItem from "../components/FeaturedArticleItem";
import Container from "../components/Container";
import Columns from "../components/Columns";
import { DefaultButton } from "office-ui-fabric-react";
import "office-ui-fabric-react/dist/css/fabric.min.css";

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postIndex: 8
    };
  }

  _loadMorePosts() {
    this.setState({ postIndex: this.state.postIndex + 5 });
  }

  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    let visiblePosts = [];
    let featuredPosts = [];
    posts.forEach((post) => {
      if (
        post.node.frontmatter.tags.indexOf("Featured") !== -1 &&
        featuredPosts.length < 2
      )
        featuredPosts.push(post);
      else visiblePosts.push(post);
    });

    visiblePosts = visiblePosts.slice(0, this.state.postIndex);

    return (
      <Layout>
        <section className="section">
          <Container>
            <div
              className="content flex-vertical-center"
              style={{ marginBottom: 0 }}
            >
              <h1
                style={{ marginBottom: 16, marginTop: 16 }}
                className="has-text-weight-bold is-size-1"
              >
                The Technical Review
              </h1>
              <h2
                style={{ marginBottom: 16, marginTop: 0 }}
                className="has-text-weight-bold is-size-4"
              >
                Technology News & Product Reviews
              </h2>
            </div>
            <h2 className="has-text-weight-bold is-size-5 primary-heading">
              Featured Review
            </h2>
            <FeaturedArticleItem
              key={featuredPosts[0].node.id}
              post={featuredPosts[0].node}
            />
            <Columns>
              <div className="column is-two-thirds">
                <h2 className="has-text-weight-bold is-size-5 primary-heading">
                  Latest News - Reviews
                </h2>
                {visiblePosts.map(({ node: post }) => (
                  <ArticleItem key={post.id} post={post} />
                ))}
                <div style={{ display: "flex" }}>
                  {posts.length >= this.state.postIndex && (
                    <DefaultButton
                      style={{ margin: "0 auto" }}
                      onClick={() => this._loadMorePosts()}
                      text="More Articles and Reviews"
                    />
                  )}
                </div>
              </div>
              <div className="column">
                <h2 className="has-text-weight-bold is-size-5 primary-heading">
                  Other News
                </h2>
                <h2 className="has-text-weight-bold is-size-6 primary-heading">
                  From Google
                </h2>
                <GoogleFeed />
                <h2 className="has-text-weight-bold is-size-6 primary-heading">
                  From The Verge
                </h2>
                <TheVergeFeed />
                <h2 className="has-text-weight-bold is-size-6 primary-heading">
                  From Tech Republic
                </h2>
                <TechRepublicFeed />
                <blockquote>
                  At The Technical Review, we aim to be a unique source of
                  technology news and product reviews. We review a wide variety
                  of tech products and devices from a technical and practical
                  standpoint. We offer our users high-quality reviews and
                  recommendations at no cost.{" "}
                  <Link to="/about">Learn More.</Link>
                </blockquote>
              </div>
            </Columns>
          </Container>
        </section>
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "article-post" }
          draft: { eq: false }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            date(formatString: "MMMM DD, YYYY")
            author
            tags
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 700) {
                  ...GatsbyImageSharpSizes
                }
                fluid(maxWidth: 700, maxHeight: 420) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
