import React from "react";
import { StaticQuery, graphql } from "gatsby";
import dayjs from "dayjs";

// https://www.sitepoint.com/community/t/choose-whole-sentences-and-only-whole-sentences-reliably-with-regex/8075/10
// const FIRST_SENTENCE_REGEX = /["']?[A-Z][^.?!]+((?![.?!]['"]?\s["']?[A-Z][^.?!]).)+[.?!'"]+/g;

// function createMarkup(dangerousHtml) {
//   if (!dangerousHtml) return { __html: " " };
//   const match = dangerousHtml.match(FIRST_SENTENCE_REGEX);
//   if (match && match[0]) {
//     return { __html: match[0] };
//   }
//   return { __html: "" };
// }

function createMarkup(dangerousHtml) {
  if (!dangerousHtml) return { __html: " " };
  return { __html: dangerousHtml };
}

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      {
        allFeedTechRepublic(limit: 7, skip: 0) {
          edges {
            node {
              id
              title
              link
              pubDate
              contentSnippet
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        {data.allFeedTechRepublic.edges.map(article => (
          <div key={article.node.id} className="rss-feed-list-item">
            <h5
              className="subtitle is-6"
              style={{ marginBottom: 0, marginTop: 0 }}
            >
              <a title={article.node.title} href={article.node.link}>
                {article.node.title}
              </a>
            </h5>
            <div
              style={{ fontSize: 12, marginTop: 8 }}
              dangerouslySetInnerHTML={createMarkup(
                article.node.contentSnippet
              )}
            />

            <p style={{ color: "#757575", fontSize: 12, marginBottom: 0 }}>
              {dayjs(article.node.pubDate).format("dddd, MMM DD, YYYY")} - Tech
              Republic
            </p>
          </div>
        ))}
      </div>
    )}
  />
);

export default TemplateWrapper;
