import React from "react";
import { StaticQuery, graphql } from "gatsby";
import dayjs from "dayjs";

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      {
        allFeedGoogle(limit: 7, skip: 0) {
          edges {
            node {
              id
              title
              link
              pubDate
            }
          }
        }
      }
    `}
    render={(data) => (
      <div>
        {data.allFeedGoogle.edges.map((article) => (
          <div key={article.node.id} className="rss-feed-list-item">
            <h5
              className="subtitle is-6"
              style={{ marginBottom: 0, marginTop: 0 }}
            >
              <a title={article.node.title} href={article.node.link}>
                {article.node.title}
              </a>
            </h5>
            <p style={{ color: "#757575", fontSize: 12, marginBottom: 0 }}>
              {dayjs(article.node.pubDate).format("dddd, MMM DD, YYYY")} -
              Google
            </p>
          </div>
        ))}
      </div>
    )}
  />
);

export default TemplateWrapper;
